@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap);

html {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Space',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  background-color: hsl(227, 61%, 26%);
  margin: 0;
}

body {
  display: grid;
  min-height: 100vh;
  background: linear-gradient(
    135deg,
    hsl(227, 61%, 13%),
    hsl(227, 61%, 26%),
    hsl(227, 61%, 39%)
  );
}

body::-webkit-backdrop {
  background-color: hsl(227, 61%, 26%);
}

body::backdrop {
  background-color: hsl(227, 61%, 26%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

